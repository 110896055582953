import React from "react"
import { graphql } from "gatsby"
import gql from "graphql-tag"
import withPreview from "@components/withPreview"

import Layout from "@components/layout"
import SEO from "@components/seo"
import SimpleBreadcrumbHero from "@components/common/SimpleBreadcrumbHero"
import SplitTextImageSection from "@templates/homepage/components/SplitTextImageSection"
import DirectoryTableFilterSection from "@templates/directory/components/DirectoryTableFilterSection"
import DirectoryDepartmentSection from "@templates/directory/components/DirectoryDepartmentSection"

import { filterPageForTemplate } from "@templates/utilities/templateUtils"
// import { makeMedicalPersonData } from "../../utilities/dataUtils"
import * as c from "@utilities/constants"

export const DirectoryIndex = props => {
  const page =
    !!props.preview && !!props.preview.page
      ? props.preview.page.preview.node
      : filterPageForTemplate(
        props.data.allWpPage.edges,
        props.pageContext.wordpressUri
      )

      console.log(props)


  const departments = props.data.allWpDepartment.edges.map(e => e.node)
  const locations = props.data.allWpLocation.edges.map(e => e.node)
  const medicalStaff = props.data.allWpDoctor.edges.map(e => e.node)
  // const medicalStaff = makeMedicalPersonData(2000, locations, departments)
  return (
    <Layout>
      <SEO title={page.acfPageMeta.metaTitle} />
      <div className="page-fade-in">
        <SimpleBreadcrumbHero
          overline={(page.acfDirectoryPage.dpageHero || {}).overline}
          pageTitle={page.acfPageMeta.metaTitle}
          title={(page.acfDirectoryPage.dpageHero || {}).title}
          image={(page.acfDirectoryPage.dpageHero || {}).image}
          overlayType="full"

        />
        <DirectoryDepartmentSection
          directoryPageUri={page.uri}
          directoryTableAnchor={c.DIRECTORY_TABLE_ANCHOR}
          departments={departments.sort()}
        />
        <DirectoryTableFilterSection
          anchor={c.DIRECTORY_TABLE_ANCHOR}
          departments={departments.sort()}
          locations={locations.filter(l => (l.acf.type || {}).slug === c.TYPE_HOSPITAL_SLUG)}
          medicalStaff={medicalStaff}
        />
        <SplitTextImageSection
          image={page.acfDirectoryPage.careerSection.image}
          imageSide="right"
          imageSize="lg"
          title={page.acfDirectoryPage.careerSection.title}
          subcopy={page.acfDirectoryPage.careerSection.subcopy}
          cta2={page.acfDirectoryPage.careerSection.cta}
        />
        {/* {<pre>{JSON.stringify(page, null, 2)}</pre>} */}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query DirectoryPageQuery {
    allWpPage(filter: { acfPageMeta: { pageTemplate: { eq: "directory" } } }) {
      edges {
        node {
          id
          uri
          acfDirectoryPage {
            dpageHero {
              overline
              title
              image {
                altText
                sourceUrl
              }
            }
            careerSection {
              cta {
                title
                url
              }
              image {
                altText
                sourceUrl
              }
              title
              subcopy
            }
          }
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
        }
      }
    }
    allWpDoctor(filter: { acf: { publishFinal: { eq: true } } }) {
      edges {
        node {
          uri
          title
          acf {
            title
            departments {
              name
              id
            }
            locations {
              ... on WpLocation {
                id
                title
                acf {
                  publishFinal
                }
              }
            }
          }
        }
      }
    }
    allWpDepartment {
      edges {
        node {
          name
          id
        }
      }
    }
    allWpLocation {
      edges {
        node {
          id
          title
          acf {
            type {
              slug
            }
          }
        }
      }
    }
  }
`

const PREVIEW_QUERY = gql`
  query PreviewDirectoryQuery($id: ID!) {
    page(id: $id, idType: DATABASE_ID) {
      preview {
        node {
          id
          uri
          acfDirectoryPage {
            dpageHero {
              overline
              title
            }
            careerSection {
              cta {
                title
                url
              }
              image {
                altText
                sourceUrl
              }
              title
              subcopy
            }
          }
          acfPageMeta {
            metaImage {
              altText
              sourceUrl
            }
            metaDescription
            metaTitle
          }
        }
      }
    }
  }
`

export default withPreview({ preview: PREVIEW_QUERY })(DirectoryIndex)
