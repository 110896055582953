import React from "react"
import PropTypes from "prop-types"

import Table from "@components/common/Table"

import UniversalLink from "@components/common/UniversalLink"
import BodyText from "@components/common/BodyText"
import IconLinkButton from "@components/common/IconLinkButton"
import { ReactComponent as ChevronRight } from "@images/icons/chevron-right.svg"

import { returnPublishFinal } from "@utilities/dataUtils"

import language from "@language"

import "./style.scss"

const DirectoryTable = props => {
  const displayMatchingDepartment = (departments = [], departmentFilterId) => {
    return departments.length
      ? departments.find((d, index) => {
          if (departmentFilterId) {
            return d.id === departmentFilterId // return the department that the filter matches
          } else {
            return index === 0 // or return first
          }
        }).name
      : ""
  }

  const displayMatchingLocation = (locations = [], locationFilterId) => {
    return locations.length
      ? locations.find((d, index) => {
          if (locationFilterId) {
            return d.id === locationFilterId // return the location that the filter matches
          } else {
            return index === 0 // or return first
          }
        }).title
      : ""
  }

  const columns = React.useMemo(
    () => [
      {
        Header: language[props.language].name,
        accessor: "name",
        Cell: rowInfo => {
          return (
            <UniversalLink href={rowInfo.row.original.uri}>
              <BodyText className="text-link" color="primary" size="sm">
                {rowInfo.value}
              </BodyText>
            </UniversalLink>
          )
        },
      },
      {
        Header: language[props.language].title,
        accessor: "title",
        Cell: rowInfo => (
          <BodyText
            key={`title--${rowInfo.value}`}
            fontWeight="300"
            color="dark-gray"
            size="sm"
          >
            {rowInfo.value}
          </BodyText>
        ),
      },
      {
        Header: language[props.language].department,
        accessor: "department",
        Cell: rowInfo => (
          <BodyText
            key={`department--${rowInfo.value}`}
            fontWeight="300"
            color="dark-gray"
            size="sm"
          >
            {rowInfo.value}
          </BodyText>
        ),
      },
      {
        Header: language[props.language].location,
        accessor: "location",
        Cell: rowInfo => (
          <BodyText
            key={`location--${rowInfo.value}`}
            fontWeight="300"
            color="dark-gray"
            size="sm"
          >
            {rowInfo.value}
          </BodyText>
        ),
      },
      {
        Header: "",
        accessor: "moreInfo",
        disableFilters: true,
        disableSortBy: true,
      },
    ],
    [props.language]
  )

  const data = React.useMemo(() => {
    const constructMedicalStaffRowData = (
      langCode,
      medicalStaff,
      departmentFilterId = null,
      locationFilterId = null
    ) => {
      return {
        uri: medicalStaff.uri,
        name: medicalStaff.title,
        title: medicalStaff.acf.title,

        department: displayMatchingDepartment(
          medicalStaff.acf.departments,
          departmentFilterId
        ),
        location: displayMatchingLocation(
          medicalStaff.acf.locations.filter(l => !!returnPublishFinal(l)),
          locationFilterId
        ),
        moreInfo: (
          <IconLinkButton
            uri={medicalStaff.uri}
            iconSide="right"
            text={language[langCode].more_info}
            textcolor="primary"
            icon={<ChevronRight />}
          />
        ),
      }
    }

    return props.medicalStaff.map((staff, index) =>
      constructMedicalStaffRowData(
        props.language,
        staff,
        props.departmentFilterId,
        props.locationFilterId
      )
    )
  }, [
    props.language,
    props.medicalStaff,
    props.departmentFilterId,
    props.locationFilterId,
  ])

  return <Table columns={columns} data={data} language={props.language} />
}

DirectoryTable.propTypes = {
  departmentFilterId: PropTypes.string,
  locationFilterId: PropTypes.string,
  medicalStaff: PropTypes.array,
  language: PropTypes.string,
}
DirectoryTable.defaultProps = {
  medicalStaff: [],
  language: "es",
}

export default DirectoryTable
