import React from "react"
import PropTypes from "prop-types"
import classnames from "classnames"

import "./style.scss"

const DualButton = props => {
  return (
    <div className={classnames("dual-button", props.className)}>
      {props.button1 && (
        <div className="dual-button__button1">{props.button1}</div>
      )}
      {props.button2 && (
        <div className="dual-button__button2">{props.button2}</div>
      )}
    </div>
  )
}

DualButton.propTypes = {
  button1: PropTypes.object,
  button2: PropTypes.object,
  className: PropTypes.string,
}
DualButton.defaultProps = {}

export default DualButton
