import React from "react"
import PropTypes from "prop-types"

import HeadingText from "@components/common/HeadingText"
import BodyText from "@components/common/BodyText"
import Markdown from "markdown-to-jsx"
import DualButton from "@components/common/DualButton"
import BrandButton from "@components/common/BrandButton"
import CustomImage from "@components/common/CustomImage"

import { preFilterMarkdown } from "@utilities/markdownUtils"
import classnames from "classnames"

import "./style.scss"

const SplitTextImageSection = props => {
  return (
    <div
      className={`split-text-image-section content-spacing--xl bg-${props.backgroundColorToken}`}
    >
      <div className="split-text-image-section__wrapper page-wrap">
        <div
          className={classnames(
            "row",
            `split-text-image-section--${props.imageSide}`
          )}
        >
          <div
            className={classnames(
              "split-text-image-section__image",
              `split-text-image-section__image--${props.imageSide}`,
              `split-text-image-section__image--${props.imageSize}`,
              "col-lg-6"
            )}
          >
            {props.image && (
              <CustomImage
                src={props.image.sourceUrl}
                alt={props.image.altText}
                width={props.imageSize === "default" ? 820 : 1000}
              />
            )}
          </div>
          <div
            className={classnames(
              "split-text-image-section__text",
              `col-lg-${6 - props.textSectionOffset}`,
              `offset-lg-${props.textSectionOffset}`
            )}
          >
            <div className="split-text-image-section__text-wrapper">
              {props.overline && (
                <HeadingText
                  className="mb-40px"
                  size="md"
                  color="dark"
                  text={props.overline}
                />
              )}
              <HeadingText
                className="split-text-image-section__title "
                size={props.titleHeadingSize}
                color={props.titleColorToken}
                text={props.title}
              />
              {props.subcopy && (
                <Markdown
                  className="split-text-image-section__subcopy"
                  size={props.bodySize}
                  color={props.bodyColorToken}
                  options={{
                    overrides: {
                      p: {
                        component: props.bodyComponent,
                        props: {
                          color: props.bodyColorToken,
                          size: props.bodySize,
                        },
                      },
                    },
                  }}
                >
                  {preFilterMarkdown(props.subcopy)}
                </Markdown>
              )}
            </div>
            <DualButton
              className="split-text-image-section__cta mt-20px"
              button1={
                props.cta1 && (
                  <BrandButton
                    size="md"
                    color="primary"
                    text={props.cta1.title}
                    href={props.cta1.url}
                  />
                )
              }
              button2={
                props.cta2 && (
                  <BrandButton
                    size="md"
                    color="secondary"
                    text={props.cta2.title}
                    href={props.cta2.url}
                  />
                )
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

SplitTextImageSection.propTypes = {
  backgroundColorToken: PropTypes.oneOf(["white", "light-gray"]),
  bodyComponent: PropTypes.func,
  bodyColorToken: PropTypes.string,
  bodySize: PropTypes.string,
  image: PropTypes.shape({
    sourceUrl: PropTypes.string,
    altText: PropTypes.string,
  }),
  imageSide: PropTypes.oneOf(["left", "right"]),
  imageSize: PropTypes.oneOf(["default", "lg"]),
  textSectionOffset: PropTypes.number,
  overline: PropTypes.string,
  title: PropTypes.string,
  titleHeadingSize: PropTypes.string,
  titleColorToken: PropTypes.string,
  subCopy: PropTypes.string,
  cta1: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
  cta2: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
  }),
}
SplitTextImageSection.defaultProps = {
  backgroundColorToken: "white",
  bodyComponent: BodyText,
  bodySize: "md",
  bodyColorToken: "dark-gray",
  imageSide: "left",
  imageSize: "default",
  titleHeadingSize: "lg",
  titleColorToken: "primary",
  textSectionOffset: 1,
}

export default SplitTextImageSection
