import React from "react"
import PropTypes from "prop-types"

import HeadingText from "@components/common/HeadingText"
import UniversalLink from "@components/common/UniversalLink"
import language from "@language"
import * as c from "@utilities/constants"

import "./style.scss"

const DirectoryDepartmentSection = props => {
  return (
    <div className="department-section">
      <div className="page-wrap">
        <HeadingText
          className="department-section__title"
          color="dark"
          size="md"
          text={language[props.language].specialties}
        />
        <div className="department-section__grid">
          {props.departments.map((department, index) => {
            return (
              <UniversalLink
                key={`department-section__item--${index}`}
                className="department-section__item "
                href={`${props.directoryPageUri}?${c.DEPARTMENT_PARAM_KEY}=${department.id}#${props.directoryTableAnchor}`}
              >
                <HeadingText
                  className="text-link--green-hover"
                  color="primary-dark"
                  size="sm"
                  text={department.name}
                />
              </UniversalLink>
            )
          })}
        </div>
      </div>
    </div>
  )
}

DirectoryDepartmentSection.propTypes = {
  departments: PropTypes.array,
  directoryPageUri: PropTypes.string,
  directoryTableAnchor: PropTypes.string,
  language: PropTypes.string,
}
DirectoryDepartmentSection.defaultProps = {
  departments: [],
  language: "es",
}

export default DirectoryDepartmentSection
